import customerReducer from './customer';
import wallets from './wallets'
import transactions from './transactions'
import charts from './charts';

export default {
    customer: customerReducer,
    wallets: wallets,
    transactions,
    charts,
};
