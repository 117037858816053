import axios from 'axios';

const PROD_API = 'https://api.wsbit.io';
const TEST_LOCAL_API = 'http://192.168.8.179:8204';
const HOSTNAME = window.location.hostname;
let URL = PROD_API;

if (HOSTNAME.includes('local') || HOSTNAME.includes('192.168')) {
    URL = TEST_LOCAL_API;
}

// Универсальная функция для отправки запросов
export const sendRequest = (endpoint, method, data, query) => {
    const headers = {
        'Content-Type': 'application/json',

    };
    const token = JSON.parse(localStorage.getItem('globalStore'))?.customer
        ?.token;
        console.log(token);
    if (token) {
        headers.authorization = `Bearer ${token}`;
    }
    if (query) {
        endpoint += `?${objectToQueryString(query)}`;
    }
    return axios({
        url: `${URL}${endpoint}`,
        method: method,
        data: data,
        headers,
        withCredentials: false,
    });
};

function objectToQueryString(params) {
    return Object.keys(params)
        .map(
            (key) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(params[key]),
        )
        .join('&');
}
