export const SideBar = ({ isOpenBurger, toggleClass }) => {

    return (
        <>

            <div class="mobileNav m-y-1">
                <ul class="mobileNav__items">
                    <li class="mobileNavItem"><a class="mobileNavItem__link" href="/home"><span
                        class="mobileNavItem__icon"><span class="tw-icon tw-icon-home "
                            aria-hidden="true" role="none" data-testid="home-icon"><svg width="24"
                                height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                <path
                                    d="M22.971 13.287 12.514 2.829a.829.829 0 0 0-1.2 0L.857 13.287l1.2 1.2L4.2 12.344v8.228a.86.86 0 0 0 .857.857h13.715a.86.86 0 0 0 .857-.857v-8.228l2.142 2.143 1.2-1.2Zm-5.057 6.428h-12V10.63l6-6 6 6v9.086Z">
                                </path>
                            </svg></span></span><span class="mobileNavItem__label">Home</span></a></li>

                </ul>
            </div>
            <div class={`sidebar-container column-layout-left position-sticky ${isOpenBurger ? 'open' : ''}`}>
                <div class="nav-sidebar">
                    <div class="accessibility-center"><a href="#main"><span>Go to table of contents</span></a>
                    </div>
                    <div class="nav-sidebar__top">
                        <div class="nav-sidebar-brand"><a style={{ position: 'relative' }} href="/home"><img
                            src="https://wise.com/public-resources/assets/logos/wise-personal/logo.svg"
                            alt="Wise" /><span class="crypto-logo">bit</span></a></div>
                    </div>
                    <div class="nav-sidebar__body">
                        <ul class="m-b-2 p-x-3 list-unstyled">
                            <li aria-current="page"><a role="menuitem" class="generic-menu-item active"
                                href="/home">
                                <div class="icon-container" role="none"><span
                                    class="tw-icon tw-icon-home " aria-hidden="true" role="none"
                                    data-testid="home-icon"><svg width="24" height="24"
                                        fill="currentColor" focusable="false"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M22.971 13.287 12.514 2.829a.829.829 0 0 0-1.2 0L.857 13.287l1.2 1.2L4.2 12.344v8.228a.86.86 0 0 0 .857.857h13.715a.86.86 0 0 0 .857-.857v-8.228l2.142 2.143 1.2-1.2Zm-5.057 6.428h-12V10.63l6-6 6 6v9.086Z">
                                        </path>
                                    </svg></span></div><span>Home</span>
                            </a>
                            </li>
                            <li aria-current="page"><a role="menuitem" class="generic-menu-item active" href="/invite"><div class="icon-container" role="none"><span class="tw-icon tw-icon-invite " aria-hidden="true" role="none" data-testid="invite-icon"><svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M9.417 7.714h1.726V6a1.714 1.714 0 1 0-1.726 1.714Zm3.44 0V6a1.714 1.714 0 1 1 1.726 1.714h-1.726Zm4.684 0A3.429 3.429 0 0 0 12 3.732a3.429 3.429 0 0 0-5.542 3.982H3.861A.861.861 0 0 0 3 8.576v3.848c0 .476.386.862.861.862h.425v7.281c0 .476.385.862.861.862h13.706a.861.861 0 0 0 .861-.862v-7.281h.425a.861.861 0 0 0 .861-.862V8.576a.861.861 0 0 0-.861-.862H17.54ZM14.587 9.43h4.699v2.142H4.714V9.43h9.873ZM6 13.286h12v6.428h-5.143v-6.428h-1.714v6.428H6v-6.428Z" clip-rule="evenodd"></path></svg></span></div><span>Earn 500WISE</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div role="presentation" class={`sidebar-toggle-backdrop column-layout-cover ${isOpenBurger ? 'in' : ''}`} ><button
                type="button" class="close" onClick={toggleClass}>
                <div class="tw-avatar nui-icon-avatar clickable tw-avatar--48 tw-avatar--icon">
                    <div class="tw-avatar__content"><span class="tw-icon tw-icon-cross "
                        aria-hidden="true" role="none" data-testid="cross-icon"><svg width="16"
                            height="16" fill="currentColor" focusable="false"
                            viewBox="0 0 24 24">
                            <path
                                d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                            </path>
                        </svg></span></div>
                </div>
            </button>
            </div>
        </>
    )
}