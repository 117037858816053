import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { singlWallet } from '../../actions/wallets';
import { useParams } from 'react-router-dom';
import TopBar from './../../components/topbar';
import { SideBar } from '../../components/sidebar';
import { transactionsSingle } from './../../actions/transactions';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';



const Item = ({ transaction }) => {

    function hide_address(address) {
        if (!address) return 'WiseBit';
        return `${address.slice(0, 7)}...${address.slice(-7)}`
    }

    return (
        <>
            <a class="ActivitySummaryV2_activitySummary"
                role="button"
                href="#"
                style={transaction.status === 'WAIT' ? { backgroundColor: 'rgb(247 147 26 / 30%)' } : transaction.type === 'INCOME' ? { backgroundColor: 'rgb(159 232 112 / 30%)' } : { backgroundColor: 'rgba(255, 0, 0, 0.25)' }}
                aria-labelledby=":R3tsuilvej6H1: :R3tsuilvej6: :R3tsuilvej6H2:">
                <div

                    class="tw-avatar ActivitySummaryV2_avatar tw-avatar--48 tw-avatar--icon">
                    <div class="tw-avatar__content"
                        style={{ backgroundImage: `url(${transaction?.wallet_to?.wallet_type?.img || transaction?.wallet_from?.wallet_type?.img})` }}

                    // style="background-color:var(--color-background-neutral)"
                    >
                        <div
                        // style="color:var(--color-content-primary)"
                        >
                            <span class="tw-icon tw-icon-groceries "
                                aria-hidden="true" role="none"
                                data-testid="groceries-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ActivitySummaryV2_summaryBoxBase ActivitySummaryV2_summaryBoxLeft"
                    id=":R3tsuilvej6:">
                    <div>
                        <p
                            class="np-text-body-large-bold ActivitySummaryV2_titleColor ActivitySummaryV2_titleBase ActivitySummaryV2_activitySummaryTitle m-b-0">
                            <strong> {transaction?.type === 'OUTCOME' ?
                                `To: ${hide_address(transaction?.wallet_to?.hash || transaction?.wallet_to_hash)}` :
                                `From: ${hide_address(transaction?.wallet_from?.hash || transaction?.wallet_from_hash)}`} </strong>
                        </p>

                    </div>
                </div>
                <div
                    class="ActivitySummaryV2_summaryBoxBase ActivitySummaryV2_summaryBoxRight">
                    <div
                        class="ActivitySummaryV2_summaryAccountWrapper">
                        <p id=":R3tsuilvej6H1:"
                            class="np-text-body-large-bold ActivitySummaryV2_titleBase ActivitySummaryV2_summaryAmount ActivitySummaryV2_noneColor m-b-0">
                            <span
                                class="parseMarkupTags_sensitive">{transaction.amount} </span>
                            {transaction?.wallet_to?.wallet_type?.code || transaction?.wallet_from?.wallet_type?.code}


                        </p>
                        <p
                            class="np-text-body-default ActivitySummaryV2_descriptionBase ActivitySummaryV2_amountInfo ActivitySummaryV2_otherColor m-b-0">
                            <span
                                class="parseMarkupTags_sensitive">{transaction.amount_usd} </span>
                            USD
                        </p>
                    </div>
                </div>
            </a>
            <br />
        </>
    )
}


const Empty = ({ }) => {
    return (
        <div class="css-26y7iw">
            <div class="p-x-2 p-b-5">
                <div style={{ maxWidth: '520px', margin: 'auto' }}>
                    <div class="text-xs-center">
                        <picture>
                            <img alt="" data-testid="wds-wallet-illustration" class="wds-illustration wds-illustration-wallet wds-illustration-padding" loading="eager" src="https://wise.com/web-art/assets/illustrations/wallet-small@1x.webp" srcset="https://wise.com/web-art/assets/illustrations/wallet-small@2x.webp 2x" width="200" height="200" />
                        </picture>
                        <h1 class="np-text-display-small text-primary m-y-3">
                            The safe home for your money
                        </h1>
                    </div>
                    <div class="p-x-2">
                        <div class="np-summary d-flex align-items-start">
                            <div class="np-summary__icon">
                                <span class="tw-icon tw-icon-payments " aria-hidden="true" role="none" data-testid="payments-icon">
                                    <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M6.857 3.43h4.359l-9.465 9.464 1.212 1.212 9.465-9.465v4.36h1.715V2.576a.861.861 0 0 0-.862-.861H6.857v1.714Zm10.286 17.142h-4.36l9.466-9.466-1.212-1.212-9.466 9.465V15H9.857v6.424c0 .476.386.862.861.862h6.425v-1.714Z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <div class="np-summary__body m-l-2">
                                <div class="np-summary__title d-flex">
                                    <span role="heading" aria-level="6" class="np-text-body-large-bold text-primary m-b-1">
                                        Add, convert, send, and receive money securely
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="np-summary d-flex align-items-start">
                            <div class="np-summary__icon">
                                <span class="tw-icon tw-icon-shield " aria-hidden="true" role="none" data-testid="shield-icon">
                                    <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                        <path d="M21.521 5.015c-.042-.429-.428-.729-.857-.729-5.443 0-7.971-2.314-7.971-2.314a.829.829 0 0 0-1.2 0S9.05 4.286 3.52 4.286c-.428 0-.814.3-.857.729 0 .128-1.671 13.586 9.172 17.228.085.043.171.043.257.043s.171 0 .257-.043c10.843-3.6 9.171-17.057 9.171-17.228Zm-2.142 6.857c-1.029 4.414-3.515 7.329-7.286 8.657-3.814-1.371-6.257-4.286-7.286-8.657-.6-2.443-.557-4.757-.471-5.871 4.2-.172 6.728-1.543 7.8-2.315 1.071.772 3.6 2.143 7.8 2.315 0 1.114.043 3.385-.557 5.871Z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <div class="np-summary__body m-l-2">
                                <div class="np-summary__title d-flex">
                                    <span role="heading" aria-level="6" class="np-text-body-large-bold text-primary m-b-1">
                                        We safeguard 100% of your cash
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="np-summary d-flex align-items-start">
                            <div class="np-summary__icon">
                                <span class="tw-icon tw-icon-id " aria-hidden="true" role="none" data-testid="id-icon">
                                    <svg width="24" height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M2.576 4.713a.861.861 0 0 0-.862.861v12.849c0 .475.386.861.862.861h18.848a.861.861 0 0 0 .862-.861V5.574a.861.861 0 0 0-.862-.861H2.576Zm.853 12.857V6.427H20.57V17.57H3.43Zm15.428-4.714h-5.571v1.714h5.571v-1.714Zm-5.571-3.429h3.428v1.715h-3.428V9.427Zm-6.429 4.286v-3.429h3v3.429h-3ZM5.143 8.999c0-.237.192-.429.428-.429h5.572c.236 0 .428.192.428.429v6a.429.429 0 0 1-.428.428H5.57A.429.429 0 0 1 5.143 15v-6Z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <div class="np-summary__body m-l-2">
                                <div class="np-summary__title d-flex">
                                    <span role="heading" aria-level="6" class="np-text-body-large-bold text-primary m-b-1">
                                        We follow strict regulations, everywhere we work
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Singl = ({ customer, transactions, wallets, singlWallet, transactionsSingle }) => {
    const { id } = useParams();
    const [isOpenBurger, setIsOpen] = useState(false);
    const toggleClass = () => {
      setIsOpen(!isOpenBurger);
    };

    useEffect(() => {
        singlWallet(id)
        transactionsSingle(id)
    }, [])



    if (!wallets.loading || !singlWallet || !wallets) {
        return (
            <div className='loader-block'>
                <div class="w-auto m-y-3 text-xs-center">
                    <div class="d-inline-flex">
                        <div class="tw-loader tw-loader--sm tw-loader--visible" data-testid="loader"></div>
                    </div>
                </div>
            </div>
        )
    } else {

        return (
            <div id="__next">
                <div class="np-theme-personal">
                    <div class="page-layout">
                        <div class="column-layout">
                            <div class="mobileNav m-y-1">
                                <ul class="mobileNav__items">
                                    <li class="mobileNavItem"><a class="mobileNavItem__link" href="/home"><span
                                        class="mobileNavItem__icon"><span class="tw-icon tw-icon-home "
                                            aria-hidden="true" role="none" data-testid="home-icon"><svg width="24"
                                                height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                                <path
                                                    d="M22.971 13.287 12.514 2.829a.829.829 0 0 0-1.2 0L.857 13.287l1.2 1.2L4.2 12.344v8.228a.86.86 0 0 0 .857.857h13.715a.86.86 0 0 0 .857-.857v-8.228l2.142 2.143 1.2-1.2Zm-5.057 6.428h-12V10.63l6-6 6 6v9.086Z">
                                                </path>
                                            </svg></span></span><span class="mobileNavItem__label">Home</span></a></li>
                                    <li class="mobileNavItem"><a class="mobileNavItem__link" href="/cards"><span
                                        class="mobileNavItem__icon"><span class="tw-icon tw-icon-card-transferwise "
                                            aria-hidden="true" role="none" data-testid="card-transferwise-icon"><svg
                                                width="24" height="24" fill="currentColor" focusable="false"
                                                viewBox="0 0 24 24">
                                                <path fill-rule="evenodd"
                                                    d="M21.424 4.714c.476 0 .862.386.862.862v6.423c0 .237-.2.424-.415.526a1.295 1.295 0 0 0-.416.322 1.783 1.783 0 0 0-.33.568 2.144 2.144 0 0 0-.125.728c0 .255.044.502.126.728.081.225.196.416.328.567.132.15.276.256.417.323.203.096.393.268.413.486h.002v2.606a.861.861 0 0 1-.862.861H2.576a.861.861 0 0 1-.862-.861V5.576c0-.476.386-.862.862-.862h18.848Zm-.853 12.242V18H3.428V6.429h17.143v4.9a3.136 3.136 0 0 0-.407.39c-.278.318-.5.696-.65 1.112-.15.416-.228.862-.228 1.312 0 .45.077.896.228 1.312.15.416.372.794.65 1.112.126.144.262.274.407.39Zm-15-5.813h2.572V9.429H5.57v1.714Z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span></span><span class="mobileNavItem__label">Card</span></a></li>
                                    {/* <li class="mobileNavItem"><a class="mobileNavItem__link" href="/recipients"><span
                                    class="mobileNavItem__icon"><span class="tw-icon tw-icon-recipients "
                                        aria-hidden="true" role="none" data-testid="recipients-icon"><svg width="24"
                                            height="24" fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                            <path
                                                d="M17.186 12.3a5.114 5.114 0 0 0 2.1-4.157C19.286 5.314 16.97 3 14.143 3a5.168 5.168 0 0 0-4.457 2.571c-2.743.086-4.972 2.358-4.972 5.143 0 1.715.815 3.215 2.1 4.157A6.796 6.796 0 0 0 3 21h1.714c0-2.014 1.2-3.771 2.872-4.629.643-.3 1.328-.514 2.1-.514h.171C12.686 15.857 15 18.171 15 21h1.714c0-2.7-1.543-5.014-3.814-6.129a5.61 5.61 0 0 0 1.414-1.585c2.743.085 4.972 2.357 4.972 5.143H21c0-2.7-1.543-5.015-3.814-6.129Zm-8.272 1.714C7.5 13.586 6.43 12.3 6.43 10.714c0-1.628 1.114-2.957 2.614-3.343.257-.042.514-.085.814-.085.343 0 .643.043.943.128 1.457.429 2.486 1.715 2.486 3.3 0 .257-.043.472-.086.729-.343 1.543-1.714 2.7-3.343 2.7-.343 0-.643-.043-.943-.129Zm6.043-2.528c.043-.257.043-.515.043-.772 0-2.228-1.414-4.114-3.429-4.843a3.376 3.376 0 0 1 2.572-1.157 3.439 3.439 0 0 1 3.428 3.429c0 1.628-1.114 2.957-2.614 3.343Z">
                                            </path>
                                        </svg></span></span><span class="mobileNavItem__label">Recipients</span></a>
                                </li> */}
                                    <li class="mobileNavItem"><a class="mobileNavItem__link" href="/account/payments"><span
                                        class="mobileNavItem__icon"><span class="tw-icon tw-icon-multi-currency "
                                            aria-hidden="true" role="none" data-testid="multi-currency-icon"><svg
                                                width="24" height="24" fill="currentColor" focusable="false"
                                                viewBox="0 0 24 24">
                                                <path fill-rule="evenodd"
                                                    d="M13.086 8.57a3.45 3.45 0 0 0 3.042-1.843 6.26 6.26 0 0 1 1.415.943 6.862 6.862 0 0 1 2.314 4.329h1.714a8.635 8.635 0 0 0-3.428-6.043 8.917 8.917 0 0 0-1.629-.943c-.085-1.843-1.585-3.3-3.428-3.3s-3.343 1.457-3.429 3.3v.128c0 .558.129 1.115.386 1.586a3.45 3.45 0 0 0 3.043 1.843Zm-1.543-4.157a1.726 1.726 0 0 1 1.543-.986c.685 0 1.285.429 1.543.986.085.214.171.471.171.728 0 .386-.129.729-.343.986-.3.429-.814.729-1.371.729-.558 0-1.072-.3-1.372-.729a1.528 1.528 0 0 1-.343-.986c0-.257.043-.471.172-.728Zm8.785 10.156c-.02-.021-.042-.032-.064-.043a.224.224 0 0 1-.064-.043c-.471-.3-1.029-.428-1.543-.471a3.46 3.46 0 0 0-3.129 1.714c-.642 1.157-.6 2.529.086 3.557-.471.3-.986.557-1.543.772a6.76 6.76 0 0 1-4.928-.172c0 .043-.857 1.543-.857 1.5a8.522 8.522 0 0 0 6.943.043 7.99 7.99 0 0 0 1.67-.943 3.407 3.407 0 0 0 4.544-1.328c.943-1.586.428-3.6-1.115-4.586Zm-.342 3.729c-.343.6-.986.9-1.629.857-.257-.043-.471-.086-.686-.215-.343-.17-.557-.47-.685-.77-.215-.472-.215-1.072.085-1.544.3-.514.772-.771 1.329-.857.343-.043.686.043 1.029.214.214.129.385.3.514.472.3.557.385 1.243.043 1.843ZM4.943 12.557c1.243-.043 2.486.557 3.129 1.714.685 1.114.6 2.486-.086 3.643-.257.428-.686.814-1.157 1.114-.043 0-.086 0-.129.043-1.586.857-3.6.257-4.543-1.329a3.478 3.478 0 0 1 1.157-4.628 7.263 7.263 0 0 1 0-1.929 8.545 8.545 0 0 1 3.515-6c-.017-.032.097.164.246.421.244.422.584 1.01.61 1.036a6.808 6.808 0 0 0-2.613 4.2 5.795 5.795 0 0 0-.13 1.715Zm1.029 4.885c.3-.171.557-.471.685-.771.257-.471.215-1.071-.043-1.543a1.72 1.72 0 0 0-1.328-.857c-.343-.043-.686.043-1.029.214-.214.129-.385.257-.514.472-.386.514-.429 1.242-.086 1.842.343.6.986.9 1.629.858.214 0 .471-.086.686-.215Z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span></span><span class="mobileNavItem__label">Payments</span></a>
                                    </li>
                                </ul>
                            </div>
                            <SideBar toggleClass={toggleClass} isOpenBurger={isOpenBurger}/>
                            <div role="presentation" class="sidebar-toggle-backdrop column-layout-cover"><button type="button"
                                class="close">
                                <div class="tw-avatar nui-icon-avatar clickable tw-avatar--48 tw-avatar--icon">
                                    <div class="tw-avatar__content"><span class="tw-icon tw-icon-cross " aria-hidden="true"
                                        role="none" data-testid="cross-icon"><svg width="16" height="16"
                                            fill="currentColor" focusable="false" viewBox="0 0 24 24">
                                            <path
                                                d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                                            </path>
                                        </svg></span></div>
                                </div>
                            </button></div>
                            <div class="column-layout-main">
                                <TopBar customer={customer} toggleClass={toggleClass}/>
                                <div id="main" class="container-content">
                                    <div class="css-79elbk">
                                        <div class="css-1obpoid">

                                            <div class="css-c152vs">
                                                <div class="css-jyy5u2">
                                                    <div>
                                                        <div class="css-iy4920">
                                                            <div class="balance-avatar balance-avatar--48">
                                                                <div class="tw-avatar tw-avatar--48 tw-avatar--thumbnail">
                                                                    <div class="tw-avatar__content"
                                                                    // style="background-color:transparent"
                                                                    >
                                                                        <img src={wallets?.singlWalletData?.wallet_type?.img} alt="" />
                                                                        {/* <div class="balance-avatar__round-currency-icon currency-flag currency-flag-pln">
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div role="heading" aria-level="2"
                                                                class="np-text-body-large css-k008qs">{wallets?.singlWalletData?.wallet_type?.code} balance
                                                                <div className={`percentBlockInline ${wallets?.singlWalletData?.percent === 0 ? 'hide' : ''}`} style={{ color: wallets?.singlWalletData?.percent < 0 ? 'red' : 'green' }}>
                                                                    {wallets?.singlWalletData?.percent < 0 ?
                                                                        (<TrendingDownIcon />) :
                                                                        (<TrendingUpIcon />)}
                                                                    <span>{wallets?.singlWalletData?.percent.toFixed(2)}%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="m-t-1 css-1ipngp">
                                                            <div class="css-nrgc68">
                                                                <h4 role="none" class="css-8ul3yv">
                                                                    <div style={{ display: 'flex', position: 'relative' }}>
                                                                        {wallets?.singlWalletData?.balance} {wallets?.singlWalletData?.wallet_type?.code}
                                                                    </div>

                                                                </h4>
                                                                <h4>{wallets?.singlWalletData?.balance_usd} USD</h4>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="css-knwtzf">
                                                    <div class="css-v1ygcs">
                                                        <div class="css-17sfd0i">
                                                            <div class="css-hugk3o">
                                                                {wallets?.singlWalletData?.wallet_type?.isGenerateCrypto ? (<div class="css-rz0j7z">
                                                                    <a
                                                                        class="np-circular-btn primary accent"
                                                                        href={`/flows/balances/${wallets?.singlWalletData?.id}`}
                                                                    >
                                                                        <input
                                                                            type="button" aria-label="Add"
                                                                            class="btn np-btn btn-accent btn-priority-1" /><span
                                                                                class="tw-icon tw-icon-plus " aria-hidden="true"
                                                                                role="none" data-testid="plus-icon"><svg width="24"
                                                                                    height="24" fill="currentColor"
                                                                                    focusable="false" viewBox="0 0 24 24">
                                                                                <path
                                                                                    d="M22.286 11.143h-9.429V1.715h-1.714v9.428H1.714v1.715h9.429v9.428h1.714v-9.428h9.429v-1.715Z">
                                                                                </path>
                                                                            </svg></span><span
                                                                                class="np-text-body-default-bold np-circular-btn__label">Add</span></a>
                                                                </div>) : (<></>)}

                                                                <div class="css-rz0j7z"><a href='/convert?131'
                                                                    class="np-circular-btn primary accent"><input
                                                                        type="button" aria-label="Convert"
                                                                        class="btn np-btn btn-accent btn-priority-1" /><span
                                                                            class="tw-icon tw-icon-convert " aria-hidden="true"
                                                                            role="none" data-testid="convert-icon"><svg
                                                                                width="24" height="24" fill="currentColor"
                                                                                focusable="false" viewBox="0 0 24 24">
                                                                            <path
                                                                                d="m22.029 11.572-6-6-1.2 1.2 4.543 4.543H4.629l4.543-4.543-1.2-1.2-6 6a.829.829 0 0 0 0 1.2l6 6 1.2-1.2-4.543-4.543h14.7l-4.5 4.543 1.2 1.2 6-6a.829.829 0 0 0 0-1.2Z">
                                                                            </path>
                                                                        </svg></span><span
                                                                            class="np-text-body-default-bold np-circular-btn__label">Convert</span></a>
                                                                </div>
                                                                <div class="css-rz0j7z"><a
                                                                    href={`/send/${wallets?.singlWalletData?.id}`}
                                                                    class="np-circular-btn secondary accent"><input
                                                                        type="button" aria-label="Send"
                                                                        class="btn np-btn btn-accent btn-priority-2" /><span
                                                                            class="tw-icon tw-icon-send " aria-hidden="true"
                                                                            role="none" data-testid="send-icon"><svg width="24"
                                                                                height="24" fill="currentColor"
                                                                                focusable="false" viewBox="0 0 24 24">
                                                                            <path
                                                                                d="M21.343 10.543 12.77 1.972a.829.829 0 0 0-1.2 0L3 10.543l1.2 1.2 7.114-7.114v17.657h1.715V4.63l7.114 7.114 1.2-1.2Z">
                                                                            </path>
                                                                        </svg></span><span
                                                                            class="np-text-body-default-bold np-circular-btn__label">Send</span></a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="css-16807jx">
                                                        <div class="css-17sfd0i">
                                                            <div class="css-hugk3o">
                                                                {wallets?.singlWalletData?.wallet_type?.isGenerateCrypto ? (<div class="css-rz0j7z">
                                                                    <a href={`/flows/balances/${wallets?.singlWalletData?.id}`}
                                                                        class="np-circular-btn primary accent">
                                                                        <input
                                                                            type="button" aria-label="Add"
                                                                            class="btn np-btn btn-accent btn-priority-1" />
                                                                        <span
                                                                            class="tw-icon tw-icon-plus " aria-hidden="true"
                                                                            role="none" data-testid="plus-icon">
                                                                            <svg width="24"
                                                                                height="24" fill="currentColor"
                                                                                focusable="false" viewBox="0 0 24 24">
                                                                                <path
                                                                                    d="M22.286 11.143h-9.429V1.715h-1.714v9.428H1.714v1.715h9.429v9.428h1.714v-9.428h9.429v-1.715Z">
                                                                                </path>
                                                                            </svg>
                                                                        </span>
                                                                        <span class="np-text-body-default-bold np-circular-btn__label">Add</span>
                                                                    </a>
                                                                </div>) : (<></>)}

                                                                <div class="css-rz0j7z"><a href='/convert?131'
                                                                    class="np-circular-btn primary accent">
                                                                    <input
                                                                        type="button" aria-label="Convert"
                                                                        class="btn np-btn btn-accent btn-priority-1" />
                                                                    <span

                                                                        class="tw-icon tw-icon-convert " aria-hidden="true"
                                                                        role="none" data-testid="convert-icon"><svg
                                                                            width="24" height="24" fill="currentColor"
                                                                            focusable="false" viewBox="0 0 24 24">
                                                                            <path d="m22.029 11.572-6-6-1.2 1.2 4.543 4.543H4.629l4.543-4.543-1.2-1.2-6 6a.829.829 0 0 0 0 1.2l6 6 1.2-1.2-4.543-4.543h14.7l-4.5 4.543 1.2 1.2 6-6a.829.829 0 0 0 0-1.2Z">
                                                                            </path>
                                                                        </svg>
                                                                    </span>
                                                                    <span class="np-text-body-default-bold np-circular-btn__label">Convert</span></a>
                                                                </div>
                                                                <div class="css-rz0j7z"><a
                                                                    href={`/send/${wallets?.singlWalletData?.id}`}
                                                                    class="np-circular-btn secondary accent"><input
                                                                        type="button" aria-label="Send"
                                                                        class="btn np-btn btn-accent btn-priority-2" /><span
                                                                            class="tw-icon tw-icon-send " aria-hidden="true"
                                                                            role="none" data-testid="send-icon"><svg width="24"
                                                                                height="24" fill="currentColor"
                                                                                focusable="false" viewBox="0 0 24 24">
                                                                            <path
                                                                                d="M21.343 10.543 12.77 1.972a.829.829 0 0 0-1.2 0L3 10.543l1.2 1.2 7.114-7.114v17.657h1.715V4.63l7.114 7.114 1.2-1.2Z">
                                                                            </path>
                                                                        </svg></span><span
                                                                            class="np-text-body-default-bold np-circular-btn__label">Send</span></a>
                                                                </div>
                                                                {/* <div class="css-rz0j7z"><label
                                                                class="np-circular-btn primary accent"><input
                                                                    type="button" aria-label="Request"
                                                                    class="btn np-btn btn-accent btn-priority-1" /><span
                                                                        class="tw-icon tw-icon-receive " aria-hidden="true"
                                                                        role="none" data-testid="receive-icon"><svg
                                                                            width="24" height="24" fill="currentColor"
                                                                            focusable="false" viewBox="0 0 24 24">
                                                                        <path
                                                                            d="m20.143 12.258-7.114 7.114V1.715h-1.715v17.657L4.2 12.258l-1.2 1.2 8.571 8.571a.846.846 0 0 0 .6.257.846.846 0 0 0 .6-.257l8.572-8.571-1.2-1.2Z">
                                                                        </path>
                                                                    </svg></span><span
                                                                        class="np-text-body-default-bold np-circular-btn__label">Request</span></label>
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="css-26y7iw">
                                            {/* <div class="m-b-1">
                                            <div class="SearchHeader_container">
                                                <div class="SearchHeader_titleContainer">
                                                    <h3 class="title-3 css-147p7uz">Transactions</h3>
                                                </div>
                                                <div class="SearchHeader_controls">
                                                    <div
                                                        class="SearchTermControl_searchInput input-group-sm SearchTermControl_sm SearchHeader_inputContainer">
                                                        <input class="form-control" placeholder="Search" type="text"
                                                            value="" />
                                                        <div class="SearchTermControl_searchInputIcon SearchTermControl_sm">
                                                            <span class="tw-icon tw-icon-search " aria-hidden="true"
                                                                role="none" data-testid="search-icon"><svg width="16"
                                                                    height="16" fill="currentColor" focusable="false"
                                                                    viewBox="0 0 24 24">
                                                                    <path
                                                                        d="m20.786 19.543-4.029-4.029c1.072-1.328 1.672-3 1.672-4.8C18.429 6.471 14.957 3 10.714 3S3 6.471 3 10.714s3.471 7.715 7.714 7.715c1.8 0 3.472-.643 4.8-1.672l4.029 4.029 1.243-1.243Zm-10.029-2.829c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6Z">
                                                                    </path>
                                                                </svg></span></div>
                                                    </div>
                                                    <div class="SearchHeader_buttonContainer"><button type="button"
                                                        class="np-action-btn np-text-body-default-bold btn-priority-1"><span
                                                            class="tw-icon tw-icon-slider m-r-1" aria-hidden="true"
                                                            role="none" data-testid="slider-icon"><svg width="16"
                                                                height="16" fill="currentColor" focusable="false"
                                                                viewBox="0 0 24 24">
                                                                <path fill-rule="evenodd"
                                                                    d="M8.143 9.428a2.143 2.143 0 1 0 0-4.285 2.143 2.143 0 0 0 0 4.285Zm0 1.715a3.859 3.859 0 0 0 3.761-3H21V6.428h-9.095a3.859 3.859 0 0 0-7.524 0h-1.38v1.715h1.38c.39 1.717 1.926 3 3.762 3Zm7.714 7.714a2.143 2.143 0 1 0 0-4.286 2.143 2.143 0 0 0 0 4.286Zm0 1.714a3.859 3.859 0 0 0 3.762-3H21v-1.714H19.62a3.859 3.859 0 0 0-7.523 0H3v1.714h9.096c.39 1.718 1.925 3 3.761 3Z"
                                                                    clip-rule="evenodd"></path>
                                                            </svg></span>Filters</button><button type="button"
                                                                class="np-action-btn np-text-body-default-bold btn-priority-1"><span
                                                                    class="tw-icon tw-icon-download m-r-1" aria-hidden="true"
                                                                    role="none" data-testid="download-icon"><svg width="16"
                                                                        height="16" fill="currentColor" focusable="false"
                                                                        viewBox="0 0 24 24">
                                                                    <path fill-rule="evenodd"
                                                                        d="M12.6 17.745a.846.846 0 0 1-.6.258.748.748 0 0 1-.6-.3L5.357 11.66l1.2-1.2 4.586 4.628V1.717h1.714v13.371l4.586-4.585 1.2 1.2-6.043 6.042ZM1.714 20.572h20.572v1.715H1.714v-1.715Z"
                                                                        clip-rule="evenodd"></path>
                                                                </svg></span>Download</button></div>
                                                </div>
                                            </div>
                                        </div> */}
                                            <div>
                                                <div>
                                                    <div class="ActivityGroup_activityGroupSection">
                                                        {
                                                            transactions?.single?.length ? (transactions?.single || []).map(el => {
                                                                return (
                                                                    <>
                                                                        <h5 class="np-text-title-group ActivityGroup_activityGroupTitle">{el.date}</h5>
                                                                        {(el.transactions || []).map(item => {
                                                                            return <Item transaction={item} />
                                                                        })}
                                                                    </>
                                                                )
                                                            }) : <Empty />
                                                        }
                                                        {/* 
                                                    <div>

                                                        {
                                                            data && data.length > 0 ? data.map((item) => <Item data={item} />) : <Empty />
                                                        }


                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ singlWallet, transactionsSingle }, dispatch)
);

export default widthConnect(Singl);