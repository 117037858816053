import {
    TRANSACTIONS_REQ,
    TRANSACTIONS_REQ_FAIL,
    SINGLE_TRANSACTIONS_REQ,
    TRANSACTIONS_EXCHANGE_REQ,
    SEND_TRANSACTIONS_REQ,
    SEND_TRANSACTIONS_REQ_FAIL,
    CLEAR_ERROR,
} from '../actions/transactions';

const initialState = {
    transactions: null,
    single: null,
    loading: false,
    error: null,
};

const transactions = (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTIONS_REQ:
            return {
                ...state,
                list: action.payload,
            };
        case TRANSACTIONS_EXCHANGE_REQ:
            return {
                ...state,
                exchange: action.payload,
            };
        case SEND_TRANSACTIONS_REQ:
            return {
                ...state,
                send: action.payload,
            };
        case SINGLE_TRANSACTIONS_REQ:
            return {
                ...state,
                single: action.payload,
            };
        case TRANSACTIONS_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SEND_TRANSACTIONS_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CLEAR_ERROR: 
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export default transactions;





