import { sendRequest } from './request';


export const transactionsApi = () => {
    return sendRequest('/transactions', 'GET');
};

export const transactionsExchangeApi = (data) => {
    return sendRequest('/transactions/exchange', 'POST', data);
}

export const transactionsSingleApi = (wallet_id) => {
    return sendRequest(`/transactions/wallet/${wallet_id}`, 'GET');
};

export const transactionsSendApi = (data) => {
    return sendRequest(`/transactions/send`, 'POST', data);
};


