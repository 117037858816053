import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';

Chart.register(...registerables);

export const ChartC = ({ rawData, chartsList }) => {
    const chartRef = useRef(null);
    const myChartRef = useRef(null);

    useEffect(() => {
        console.log(123, chartsList);
        const historyData = rawData.Data.Data.map(item => ({
            x: moment.unix(item.time),
            y: item.close
        }));

        const ctx = chartRef.current.getContext('2d');

        if (myChartRef.current) {
            myChartRef.current.destroy();
        }

        const gradientPlugin = {
            id: 'gradientBackground',
            beforeDatasetsDraw(chart) {
                const ctx = chart.ctx;
                const chartArea = chart.chartArea;
                const xAxis = chart.scales.x;
                const yAxis = chart.scales.y;

                const activePoint = chart.getActiveElements()[0];
                if (activePoint) {
                    const index = activePoint.index;
                    const dataset = chart.data.datasets[0];
                    const x = xAxis.getPixelForValue(dataset.data[index].x);

                    const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
                    gradient.addColorStop(0, '#173400');
                    gradient.addColorStop((x - chartArea.left) / chartArea.width, '#173400');
                    gradient.addColorStop((x - chartArea.left) / chartArea.width, 'rgba(211, 211, 211, 1)');
                    gradient.addColorStop(1, 'rgba(211, 211, 211, 1)');

                    ctx.save();
                    ctx.strokeStyle = gradient;
                    ctx.lineWidth = 2;
                    ctx.beginPath();
                    ctx.moveTo(chartArea.left, yAxis.getPixelForValue(dataset.data[0].y));
                    dataset.data.forEach((point, i) => {
                        const x = xAxis.getPixelForValue(point.x);
                        const y = yAxis.getPixelForValue(point.y);
                        if (i === 0) {
                            ctx.moveTo(x, y);
                        } else {
                            ctx.lineTo(x, y);
                        }
                    });
                    ctx.stroke();
                    ctx.restore();
                }
            }
        };

        myChartRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    // label: 'Цена USD/UAH',
                    data: historyData,
                    backgroundColor: 'transparent',
                    fill: true,
                    tension: 0,
                    pointRadius: 0,
                    pointHoverRadius: 6
                }]
            },
            options: {
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'hour',
                            tooltipFormat: 'DD MMM, HH:mm'
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        beginAtZero: false
                    }
                },
                plugins: {
                    tooltip: {
                        display: false,
                        callbacks: {
                            label: (tooltipItem) => `${tooltipItem.raw.y}`
                        }
                    },
                    legend: {
                        labels: {
                            style: '',
                            borderRadius: 0,
                            useBorderRadius: false,
                            boxWidth: 0,
                            boxHeight: 0,
                            // This more specific font property overrides the global property
                            font: {
                                size: 0
                            }
                        }
                    }
                },
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                hover: {
                    mode: 'index',
                    intersect: false
                }
            },
            plugins: [gradientPlugin]
        });
    }, [rawData]);

    return <canvas id="cryptoChart" ref={chartRef} width="400" height="300"></canvas>;
};

