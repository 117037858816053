export default `<!DOCTYPE html>
<html lang="en-US" dir="ltr">

<head>
    <meta charset="utf-8">
    <link rel="icon" href="/wise-com/images/favicon.png" type="image/x-icon">
    <link rel="apple-touch-icon" sizes="180x180" href="/wise-com/images/apple_touch_icon.png">
    <link rel="icon" type="/wise-com/image/png" sizes="32x32" href="/wise-com/images/favicon_32x32.png">
    <link rel="icon" type="/wise-com/image/png" sizes="16x16" href="/wise-com/images/favicon_16x16.png">
    <link rel="mask-icon" href="/wise-com/images/safari_pinned_tab.svg" color="#083400">
    <link rel="icon" href="/wise-com/images/android_chrome_192x192.png">
    <link rel="icon" href="/wise-com/images/android_chrome_256x256.png">
    <link rel="preconnect" href="https://gtm.wise.com">
    <link rel="dns-prefetch" href="https://gtm.wise.com">
    <link rel="preconnect" href="https://daw291njkc3ao.cloudfront.net">
    <link rel="dns-prefetch" href="https://daw291njkc3ao.cloudfront.net">
    <link rel="preconnect" href="https://dq8dwmysp7hk1.cloudfront.net">
    <link rel="dns-prefetch" href="https://dq8dwmysp7hk1.cloudfront.net">
    <link rel="stylesheet" href="/wise-com/css/73fDF2c8c.css">
    <style type="text/css">
        .loader-block {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999;
        }
        .fresnel-container {
            margin: 0;
            padding: 0;
        }

        @media not all and (min-width:0px) and (max-width:479.98px) {
            .fresnel-at-0 {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) and (max-width:575.98px) {
            .fresnel-at-xs {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) and (max-width:767.98px) {
            .fresnel-at-sm {
                display: none !important;
            }
        }

        @media not all and (min-width:768px) and (max-width:991.98px) {
            .fresnel-at-md {
                display: none !important;
            }
        }

        @media not all and (min-width:992px) and (max-width:1199.98px) {
            .fresnel-at-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:1200px) and (max-width:1439.98px) {
            .fresnel-at-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:1440px) {
            .fresnel-at-2xl {
                display: none !important;
            }
        }

        @media not all and (max-width:479.98px) {
            .fresnel-lessThan-xs {
                display: none !important;
            }
        }

        @media not all and (max-width:575.98px) {
            .fresnel-lessThan-sm {
                display: none !important;
            }
        }

        @media not all and (max-width:767.98px) {
            .fresnel-lessThan-md {
                display: none !important;
            }
        }

        @media not all and (max-width:991.98px) {
            .fresnel-lessThan-lg {
                display: none !important;
            }
        }

        @media not all and (max-width:1199.98px) {
            .fresnel-lessThan-xl {
                display: none !important;
            }
        }

        @media not all and (max-width:1439.98px) {
            .fresnel-lessThan-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) {
            .fresnel-greaterThan-0 {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) {
            .fresnel-greaterThan-xs {
                display: none !important;
            }
        }

        @media not all and (min-width:768px) {
            .fresnel-greaterThan-sm {
                display: none !important;
            }
        }

        @media not all and (min-width:992px) {
            .fresnel-greaterThan-md {
                display: none !important;
            }
        }

        @media not all and (min-width:1200px) {
            .fresnel-greaterThan-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:1440px) {
            .fresnel-greaterThan-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) {
            .fresnel-greaterThanOrEqual-0 {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) {
            .fresnel-greaterThanOrEqual-xs {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) {
            .fresnel-greaterThanOrEqual-sm {
                display: none !important;
            }
        }

        @media not all and (min-width:768px) {
            .fresnel-greaterThanOrEqual-md {
                display: none !important;
            }
        }

        @media not all and (min-width:992px) {
            .fresnel-greaterThanOrEqual-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:1200px) {
            .fresnel-greaterThanOrEqual-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:1440px) {
            .fresnel-greaterThanOrEqual-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) and (max-width:479.98px) {
            .fresnel-between-0-xs {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) and (max-width:575.98px) {
            .fresnel-between-0-sm {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) and (max-width:767.98px) {
            .fresnel-between-0-md {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) and (max-width:991.98px) {
            .fresnel-between-0-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) and (max-width:1199.98px) {
            .fresnel-between-0-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:0px) and (max-width:1439.98px) {
            .fresnel-between-0-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) and (max-width:575.98px) {
            .fresnel-between-xs-sm {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) and (max-width:767.98px) {
            .fresnel-between-xs-md {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) and (max-width:991.98px) {
            .fresnel-between-xs-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) and (max-width:1199.98px) {
            .fresnel-between-xs-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:480px) and (max-width:1439.98px) {
            .fresnel-between-xs-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) and (max-width:767.98px) {
            .fresnel-between-sm-md {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) and (max-width:991.98px) {
            .fresnel-between-sm-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) and (max-width:1199.98px) {
            .fresnel-between-sm-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:576px) and (max-width:1439.98px) {
            .fresnel-between-sm-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:768px) and (max-width:991.98px) {
            .fresnel-between-md-lg {
                display: none !important;
            }
        }

        @media not all and (min-width:768px) and (max-width:1199.98px) {
            .fresnel-between-md-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:768px) and (max-width:1439.98px) {
            .fresnel-between-md-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:992px) and (max-width:1199.98px) {
            .fresnel-between-lg-xl {
                display: none !important;
            }
        }

        @media not all and (min-width:992px) and (max-width:1439.98px) {
            .fresnel-between-lg-2xl {
                display: none !important;
            }
        }

        @media not all and (min-width:1200px) and (max-width:1439.98px) {
            .fresnel-between-xl-2xl {
                display: none !important;
            }
        }
    </style>
    <link rel="preload" href="/static-assets/app/_next/static/media/WiseSans-Heavy.4bf7de7a.woff2" as="font"
        type="font/woff2" crossorigin="anonymous">
    <link rel="preload" href="/static-assets/app/_next/static/media/inter-latin-variable-wghtOnly-normal.40c45725.woff2"
        as="font" type="font/woff2" crossorigin="anonymous">
    <script async="" src="wisetag"></script>
    <script type="application/javascript" src="js/cookie-consent.js" async=""></script>
    <meta name="viewport" content="initial-scale=1.0, width=device-width">
    <link rel="icon" href="/wise-com/images/favicon.png" type="image/x-icon">
    <link rel="apple-touch-icon" sizes="180x180" href="/wise-com/images/apple_touch_icon.png">
    <link rel="icon" type="image/png" sizes="32x32" href="/wise-com/images/favicon_32x32.png">
    <link rel="icon" type="image/png" sizes="16x16" href="/wise-com/images/favicon_16x16.png">
    <link rel="mask-icon" href="/wise-com/images/safari_pinned_tab.svg" color="#083400">
    <link rel="icon" href="/wise-com/images/android_chrome_192x192.png">
    <link rel="icon" href="/wise-com/images/android_chrome_256x256.png">
    <meta name="msapplication-square150x150logo"
        content="https://wise.com/public-resources/assets/icons/wise-personal/mstile_150x150.png">
    
    <title>Wise, Formerly TransferWise: Online Money Transfers | International Banking Features</title>
    <meta name="twitter:title"
        content="Wise, Formerly TransferWise: Online Money Transfers | International Banking Features">
    <meta property="og:title"
        content="Wise, Formerly TransferWise: Online Money Transfers | International Banking Features">
    <meta name="description"
        content="Banks charge a lot for overseas transfers. We don't. Transfer money abroad easily and quickly with our low cost money transfers.">
    <meta name="twitter:description"
        content="Banks charge a lot for overseas transfers. We don't. Transfer money abroad easily and quickly with our low cost money transfers.">
    <meta property="og:description"
        content="Banks charge a lot for overseas transfers. We don't. Transfer money abroad easily and quickly with our low cost money transfers.">
    <meta name="robots" content="index, follow">
    <meta name="next-head-count" content="77">

    <link data-next-font="" rel="preconnect" href="/" crossorigin="anonymous">
    <link rel="preload" href="/static-assets/app/_next/static/css/45a95eaf34e21e6d.css" as="style">
    <link rel="stylesheet" href="/wise-com/css/45a95eaf34e21e6d.css" data-n-g="">
    <link rel="preload" href="/static-assets/app/_next/static/css/6b704de5f4efae4c.css" as="style">
    <link rel="stylesheet" href="/wise-com/css/6b704de5f4efae4c.css" data-n-p="">
    <link rel="preload" href="/wise-com//static-assets/app/_next/static/css/6af995f4dd472ba9.css" as="style">
    <link rel="stylesheet" href="/wise-com/css/6af995f4dd472ba9.css" data-n-p=""><
    <script defer="" nomodule="" src="js/polyfills-78c92fac7aa8fdd8.js"></script>
    <script src="/wise-com/js/webpack-d3d7d6596b7fd4a7.js" defer=""></script>
    <script src="/wise-com/js/framework-ec1cef354dc48971.js" defer=""></script>
    <script src="/wise-com/js/main-da7f95fb2e46bc93.js" defer=""></script>
    <script src="/wise-com/js/_app-0f3fb7c8d9cb2c94.js" defer=""></script>
    <script src="/wise-com/js/fd6474d5-98facc33578602c1.js" defer=""></script>
    <script src="/wise-com/js/7913-b6fc768cab33740b.js" defer=""></script>
    <script src="/wise-com/js/7299-ccca97e0d70f4d1a.js" defer=""></script>
    <script src="/wise-com/js/7028-24dbce3f114486cf.js" defer=""></script>
    <script src="/wise-com/js/1065-6f2371e892d573af.js" defer=""></script>
    <script src="/wise-com/js/6016-8ca9a4b2fc7fcd33.js" defer=""></script>
    <script src="/wise-com/js/7530-66f45c26965d4c27.js" defer=""></script>
    <script src="/wise-com/js/1893-368f2322a6db401d.js" defer=""></script>
    <script src="/wise-com/js/9645-c2028150f8d06676.js" defer=""></script>
    <script src="/wise-com/js/5936-8823bc339bcbcac8.js" defer=""></script>
    <script src="/wise-com/js/2755-df6423661a16fea7.js" defer=""></script>
    <script src="/wise-com/js/3149-15b6bc727f38676f.js" defer=""></script>
    <script src="/wise-com/js/2394-cfbdb75b50f38c99.js" defer=""></script>
    <script src="/wise-com/js/index-0301c498c01e8057.js" defer=""></script>
    <script src="/wise-com/js/_buildManifest.js" defer=""></script>
    <script src="/wise-com/js/_ssgManifest.js" defer=""></script>    
</head>

<body>
    <div id="__next">
        <div class="np-theme-personal">
            <div class="main-wrapper main-nl">
                <div class="np-theme-personal nav-banner-shown">
                    <div class="Navigation_navContainer__9M9zv"><a href="#main"
                            class="btn btn-primary tw-public-nav-btn-skip">Skip to main content</a>
                        <div data-testid="header-container" class="tw-public-nav-container is-sticky">
                            <header data-tracking-id="public-navigation" class="tw-public-nav tw-public-nav--en_US">
                                <div class="tw-public-nav__grid">
                                    <div class="tw-public-nav__header">
                                    
                                    <div
                                            class=" tw-public-nav__menu-toggle--open is-collapsed"
                                            aria-controls="navbar" aria-expanded="false" type="button"
                                            aria-label="Toggle navigation">
                                            
                                                                                         <a class="btn btn-sm np-btn np-btn-sm btn-accent btn-priority-1 tw-public-nav__item-link btn-priority-2" href="/login/" target="_top" aria-live="off">Log in</a>

                                                
                                    </div>


                                                
                                        <div data-testid="logo" class="tw-public-nav__logo "><a href="/"
                                                class="tw-public-nav__logo-link tw-public-nav__logo-link--text"
                                                aria-label="Wise"><span aria-label="Wise" role="img" class="np-logo">
                                                    <svg class="np-logo-svg np-logo-svg--size-sm"
                                                        xmlns="http://www.w3.org/2000/svg" width="26" height="24"
                                                        fill="none">
                                                        <path fill="#163300"
                                                            d="M6.826 7.232 0 15.21h12.188l1.37-3.762H8.335l3.191-3.69.01-.098L9.461 4.09h9.336L11.56 24h4.953L25.25 0H2.676l4.15 7.232Z">
                                                        </path>
                                                    </svg>
                                                    <svg class="np-logo-svg np-logo-svg--size-md"
                                                        xmlns="http://www.w3.org/2000/svg" width="106" height="24"
                                                        fill="none">
                                                        <path fill="#163300"
                                                            d="M58.738.359h6.498l-3.27 23.322h-6.498L58.739.359Zm-8.193 0L46.16 13.794 44.247.359h-4.545L33.96 13.754 33.243.36h-6.299l2.193 23.322h5.223l6.458-14.75 2.272 14.75h5.143L56.725.359h-6.18Zm54.558 13.555H89.674c.08 3.03 1.894 5.023 4.565 5.023 2.014 0 3.608-1.077 4.844-3.13l5.208 2.368C102.501 21.702 98.729 24 94.08 24c-6.339 0-10.545-4.266-10.545-11.123C83.535 5.342 88.478 0 95.455 0c6.14 0 10.007 4.146 10.007 10.605 0 1.076-.12 2.152-.359 3.309Zm-5.78-4.466c0-2.71-1.516-4.425-3.947-4.425-2.512 0-4.585 1.794-5.144 4.425h9.09ZM6.632 7.387 0 15.139h11.844l1.33-3.655H8.1l3.1-3.586.01-.095-2.016-3.471h9.072l-7.032 19.35h4.812L24.538.358H2.6l4.033 7.028Zm69.168-2.364c2.292 0 4.3 1.233 6.055 3.346l.921-6.575C81.143.688 78.93 0 76 0c-5.82 0-9.09 3.409-9.09 7.734 0 3 1.675 4.834 4.426 6.02l1.315.598c2.452 1.047 3.11 1.565 3.11 2.671 0 1.146-1.106 1.874-2.79 1.874-2.782.01-5.034-1.415-6.728-3.847l-.94 6.699C67.234 23.22 69.708 24 72.97 24c5.532 0 8.93-3.19 8.93-7.615 0-3.01-1.335-4.943-4.704-6.458l-1.435-.678c-1.994-.887-2.671-1.375-2.671-2.352 0-1.056.927-1.874 2.71-1.874Z">
                                                        </path>
                                                    </svg>
                                                    <span class="crypto-logo">bit</span>
                                                </span></a></div><button
                                            class="tw-public-nav__menu-toggle tw-public-nav__menu-toggle--close is-collapsed"
                                            aria-controls="navbar" aria-expanded="false" type="button"
                                            aria-label="Toggle navigation"><span class="tw-icon tw-icon-cross "
                                                aria-hidden="true" role="none" data-testid="cross-icon"><svg width="24"
                                                    height="24" fill="currentColor" focusable="false"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z">
                                                    </path>
                                                </svg></span></button>
                                    </div>
                                    <nav id="navbar" class="tw-public-nav__menu is-collapsed">
                                        <ul data-testid="item-container"
                                            class="tw-public-nav__list tw-public-nav__main">
                                            
                                      
                                        </ul>
                                        <ul data-testid="item-container"
                                            class="tw-public-nav__list tw-public-nav__cta tw-public-nav--fade-in">
                                            <li class="tw-public-nav__item"><a
                                                    class="btn btn-sm np-btn np-btn-sm btn-accent btn-priority-1 tw-public-nav__item-link btn-priority-2"
                                                    href="/login/" target="_top" aria-live="off">Log in</a></li>
                                            <li class="tw-public-nav__item">
                                            <a
                                                    class="btn btn-sm np-btn np-btn-sm btn-accent btn-priority-1 tw-public-nav__item-link"
                                                    href="/register"
                                                    target="_top" aria-live="off">Register</a>
                                                  
                                                    
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </header>
                        </div>
                    </div>
                </div>
                <main class="grid--xl" id="main" role="main">
                    <section class="section section--pad-sm">
                        <div class="container text-center">
                            <h1 class="mw-display-1 m-b-4">Money for here, there and everywhere</h1>
                            <div class="d-flex justify-content-center m-b-3">
                                <div class="col-md-7 col-lg-6">
                                    <p class="mw-body-1">160 countries. 40 currencies. Get the account built to save you
                                        money round the world.</p>
                                </div>
                            </div>
                            <div class="mw-cta-set mw-gap-3 justify-content-center m-b-5"><a
                                    class="btn btn-md np-btn np-btn-md btn-accent btn-priority-1 mw-cta"
                                    href="/register" aria-live="off">Open
                                    an account</a><a
                                    class="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 mw-cta"
                                    href="/register" aria-live="off">Send money now</a></div>
                            <div class="heroContainer globe">
                                <div class="_wrapper_py2kp_1">
                                    <video loop="12" muted="" autoplay="" playsinline="" tabindex="0"
                                        style="pointer-events:none;-moz-backface-visibility:hidden;-webkit-backface-visibility:hidden;-webkit-mask-image:-webkit-radial-gradient(white, black)"
                                        poster="https://wise.com/static-assets/app/_next/static/media/poster.ff25224b.jpg">
                                        <source
                                            src="https://wise.com/static-assets/app/_next/static/media/3d-globe-72a5cb0edcbe31cf385aa34558b1c435.webm"
                                            type="video/webm; codecs=av01.0.12M.08.0.110.01.01.01.0">
                                        <source
                                            src="https://wise.com/static-assets/app/_next/static/media/3d-globe-cfe618f7f706583422789cd9773a062a.mp4"
                                            type="video/mp4">
                                    </video>
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                  
                    <section class="p-b-0 section">
                        <div class="FlagsMarquee_container__gYbR5">
                            <div class="d-flex flex-no-wrap full-height">
                                <div class="FlagsMarquee_arrowContainer__SgSRK">
                                    <div class="FlagsMarquee_flagContainer__JyHKE"><span
                                            class="tw-icon tw-icon-arrow-right FlagsMarquee_arrowFlag__3H8JN FlagsMarquee_flagContainer__JyHKE"
                                            aria-hidden="true" role="none" data-testid="arrow-right-icon"><svg
                                                width="16" height="16" fill="currentColor" focusable="false"
                                                viewBox="0 0 24 24">
                                                <path
                                                    d="m22.029 11.57-8.572-8.572-1.2 1.2 7.115 7.114H1.713v1.715h17.658l-7.115 7.114 1.2 1.2 8.572-8.572a.829.829 0 0 0 0-1.2Z">
                                                </path>
                                            </svg></span></div>
                                </div><img class="FlagsMarquee_flagContainer__JyHKE" src="/wise-com/images/eu-detailed.svg"
                                    alt="European Union Flag" loading="lazy"><img
                                    class="FlagsMarquee_flagContainer__JyHKE" src="/wise-com/images/in-detailed.svg"
                                    alt="India Flag" loading="lazy"><img class="FlagsMarquee_flagContainer__JyHKE"
                                    src="/wise-com/images/us-detailed.svg" alt="United States Flag" loading="lazy"><img
                                    class="FlagsMarquee_flagContainer__JyHKE" src="/wise-com/images/tr.svg" alt="Türkiye Flag"
                                    loading="lazy"><img class="FlagsMarquee_flagContainer__JyHKE" src="/wise-com/images/kr.svg"
                                    alt="South Korea Flag" loading="lazy">
                            </div>
                        </div>
                    </section>
                    <section class="p-b-0 section section--pad-sm">
                        <div class="mw-container">
                            <div
                                class="mw-gap-12 align-items-center mw-cluster--reverse mw-cluster-md--reverse mw-cluster-md-2 mw-gap-lg-24">
                                <div class="Slide2_slide2__content__srjzp">
                                    <h2 class="mw-heading-2 m-b-4">Manage your money worldwide</h2>
                                    <div class="mw-body-1 m-b-4">Save money when you send, spend and get paid in
                                        different currencies. All you need, in one account, whenever you need it.</div>
                                    <div class="mw-cta-set mw-gap-3"><a
                                            class="btn btn-md np-btn np-btn-md btn-accent btn-priority-1 mw-cta"
                                            href="/register"
                                            aria-live="off">Open an account</a></div>
                                    
                                </div>
                                <div>
                                    <div class="_wrapper_py2kp_1">
                                        <div class="_container_nssta_1">
                                            <div class="d-flex justify-content-center"><video loop="" muted=""
                                                    autoplay="" playsinline="" tabindex="0"
                                                    style="pointer-events:none;-moz-backface-visibility:hidden;-webkit-backface-visibility:hidden;-webkit-mask-image:-webkit-radial-gradient(white, black)"
                                                    loading="lazy"
                                                    poster="/static-assets/app/_next/static/media/manage-money.404122e5.jpg"
                                                    class="media__bg"
                                                    aria-label="A phone sits on a table, with the Wise app open. The screen scrolls between US dollars, Euros and Indian rupees with transactions showing travel and life in different countries. Then new British pounds, Canadian dollar and Australian dollar accounts are opened.">
                                                    <source media="(min-width: 768px)"
                                                        src="https://wise.com/static-assets/app/_next/static/media/manage-money-877331803434eb1adf4d1be1574dbc44.webm"
                                                        type="video/webm; codecs=av01.0.12M.08.0.110.01.01.01.0">
                                                    <source
                                                        src="https://wise.com/static-assets/app/_next/static/media/manage-money-mobile-abe2f59123ca98f0cf12695071f40a8d.webm"
                                                        type="video/webm; codecs=av01.0.12M.08.0.110.01.01.01.0">
                                                    <source media="(min-width: 768px)"
                                                        src="https://wise.com/static-assets/app/_next/static/media/manage-money-d3dd9b1318284d437db6f2cdfa95f530.mp4"
                                                        type="video/mp4">
                                                    <source
                                                        src="https://wise.com/static-assets/app/_next/static/media/manage-money-mobile-1260d0f7e84132816a4f4e9d71da70c1.mp4"
                                                        type="video/mp4">
                                                </video></div>
                                            <div class="_float_nssta_5 _middle-center-all_nssta_70 _fill-desktop_nssta_161 _fill-mobile_nssta_85 resetTransform"
                                                style="width:100%;height:100%;--layered-offset:20px"
                                                translationtexts="[object Object]">
                                                <div style="width:100%;height:100%"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="section section--pad-sm">
                        <div class="Slide3_container__W2_Ii mw-container">
                            <div class="row m-b-5">
                                <div class="col-sm-12">
                                    <div class="_wrapper_py2kp_1">
                                        <div class="_container_nssta_1">
                                            <div class=""><video loop="" muted="" autoplay="" playsinline=""
                                                    tabindex="0"
                                                    style="pointer-events:none;-moz-backface-visibility:hidden;-webkit-backface-visibility:hidden;-webkit-mask-image:-webkit-radial-gradient(white, black)"
                                                    loading="lazy" class="w-100"
                                                    poster="https://wise.com/static-assets/app/_next/static/media/static.bddb1585.jpg">
                                                    <source media="(min-width: 768px)"
                                                        src="https://wise.com/static-assets/app/_next/static/media/video-1b8f565c9bd59c0fcb23a34d6d53ded1.webm"
                                                        type="video/webm; codecs=av01.0.12M.08.0.110.01.01.01.0">
                                                    <source
                                                        src="https://wise.com/static-assets/app/_next/static/media/video-mobile-60438d6f51825ee2256ba952d387c088.webm"
                                                        type="video/webm; codecs=av01.0.12M.08.0.110.01.01.01.0">
                                                    <source media="(min-width: 768px)"
                                                        src="https://wise.com/static-assets/app/_next/static/media/video-5b9b07916fd5bdf8eec80aab2b145d8f.mp4"
                                                        type="video/mp4">
                                                    <source
                                                        src="https://wise.com/static-assets/app/_next/static/media/video-mobile-6d4363df771a9829ef9d438f837533ab.mp4"
                                                        type="video/mp4">
                                                </video></div>
                                            <div class="_float_nssta_5 _bottom-left-desktop_nssta_173 _bottom-center-mobile_nssta_103 _half-desktop_nssta_164 _fill-mobile_nssta_85"
                                                style="--layered-offset:20px">
                                                <div style="width:100%"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="p-b-0 section section--pad-sm">
                        <div class="Assets_container___QCea mw-container d-flex flex-column">
                            <div class="row">
                                <div
                                    class="Assets_imageRow__Ld_2O col-sm-12 col-md-offset-1 col-md-10 col-lg-offset-0 col-lg-12 m-t-3">
                                    <picture>
                                        <source srcset="/wise-com/images/interest-2.7e531c54.jpg" media="(min-width: 481px)">
                                        <script type="text/javascript" style="display:none">
                                            //<![CDATA[
                                            window.__mirage2 = { petok: "A9iEJYcbH.0oIKPh9pfvn7GJnSbDwdSXCO.bfcBZW34-1800-0.0.1.1" };
                                            //]]>
                                        </script>
                                        <script type="text/javascript" src="js/mirage2.min.js"></script>
                                        <img class="Assets_coverImage__xz8GQ" loading="lazy"
                                            data-cfsrc="/static-assets/app/_next/static/media/interest-mobile-2.e670036c.jpg"
                                            style="display:none;visibility:hidden;"><noscript><img
                                                class="Assets_coverImage__xz8GQ" loading="lazy"
                                                src="/static-assets/app/_next/static/media/interest-mobile-2.e670036c.jpg" /></noscript>
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="section section--pad-xs">
                        <div class="mw-container">
                            <hr class="m-b-5 m-t-0">
                            <div class="row">
                                <ul class="Protection_items__aZTja">
                                    <li class="Protection_item__Ck8lF">
                                        <div class="m-r-2"><span class="tw-icon tw-icon-bank Protection_icon__brO65"
                                                aria-hidden="true" role="none" data-testid="bank-icon"><svg width="16"
                                                    height="16" fill="currentColor" focusable="false"
                                                    viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M12.905 2.658a1.304 1.304 0 0 0-1.373-.015L2.42 8.156a.87.87 0 0 0 .45 1.614h1.304v10.002h-1.74v1.739h19.131v-1.74h-1.739v-10h1.304V9.77a.869.869 0 0 0 .466-1.604l-8.69-5.508Zm5.23 5.373-5.933-3.76-6.216 3.76h12.149Zm-5.266 1.74h5.218v10H12.87v-10Zm-6.956 0v10h5.217v-10H5.913Z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span></div>
                                        <p class="mw-body-3-bold m-b-0">Wise is regulated by the <a target="_blank"
                                                rel="noreferrer">National Bank of Belgium</a></p>
                                    </li>
                                    <li class="Protection_item__Ck8lF">
                                        <div class="m-r-2"><span class="tw-icon tw-icon-people Protection_icon__brO65"
                                                aria-hidden="true" role="none" data-testid="people-icon"><svg width="16"
                                                    height="16" fill="currentColor" focusable="false"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="M17.186 12.3a5.114 5.114 0 0 0 2.1-4.157C19.286 5.314 16.97 3 14.143 3a5.168 5.168 0 0 0-4.457 2.571c-2.743.086-4.972 2.358-4.972 5.143 0 1.715.815 3.215 2.1 4.157A6.796 6.796 0 0 0 3 21h1.714c0-2.014 1.2-3.771 2.872-4.629.643-.3 1.328-.514 2.1-.514h.171C12.686 15.857 15 18.171 15 21h1.714c0-2.7-1.543-5.014-3.814-6.129a5.61 5.61 0 0 0 1.414-1.585c2.743.085 4.972 2.357 4.972 5.143H21c0-2.7-1.543-5.015-3.814-6.129Zm-8.272 1.714C7.5 13.586 6.43 12.3 6.43 10.714c0-1.628 1.114-2.957 2.614-3.343.257-.042.514-.085.814-.085.343 0 .643.043.943.128 1.457.429 2.486 1.715 2.486 3.3 0 .257-.043.472-.086.729-.343 1.543-1.714 2.7-3.343 2.7-.343 0-.643-.043-.943-.129Zm6.043-2.528c.043-.257.043-.515.043-.772 0-2.228-1.414-4.114-3.429-4.843a3.376 3.376 0 0 1 2.572-1.157 3.439 3.439 0 0 1 3.428 3.429c0 1.628-1.114 2.957-2.614 3.343Z">
                                                    </path>
                                                </svg></span></div>
                                        <p class="mw-body-3-bold m-b-0">10m customers move £30bn with Wise every quarter
                                        </p>
                                    </li>
                                    <li class="Protection_item__Ck8lF">
                                        <div class="m-r-2"><span class="tw-icon tw-icon-star Protection_icon__brO65"
                                                aria-hidden="true" role="none" data-testid="star-icon"><svg width="16"
                                                    height="16" fill="currentColor" focusable="false"
                                                    viewBox="0 0 24 24">
                                                    <path
                                                        d="M21.803 9.73c-.085-.3-.385-.558-.728-.6l-5.871-.643-2.443-5.4a.847.847 0 0 0-.772-.515.847.847 0 0 0-.771.515l-2.443 5.4-5.871.642c-.343.043-.6.258-.729.6-.086.3 0 .686.257.9l4.372 3.986-1.2 5.786c-.086.343.042.686.342.857.258.214.643.214.943.043l5.143-2.914 5.143 2.914a.723.723 0 0 0 .428.128.823.823 0 0 0 .515-.171.973.973 0 0 0 .343-.857l-1.2-5.786 4.371-3.986c.171-.257.257-.6.171-.9Zm-6.128 3.942c-.214.215-.343.515-.257.815l.9 4.414-3.9-2.272a.724.724 0 0 0-.429-.128c-.128 0-.3.043-.428.128l-3.9 2.229.9-4.371a.948.948 0 0 0-.257-.815L4.96 10.63l4.5-.514a.851.851 0 0 0 .685-.514l1.843-4.114L13.832 9.6a.944.944 0 0 0 .686.514l4.5.514-3.343 3.043Z">
                                                    </path>
                                                </svg></span></div>
                                        <p class="mw-body-3-bold m-b-0">Our average score on <a target="_blank"
                                                rel="noreferrer">WiseBit</a> is 4.3 out of 5</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section class="p-b-0 section section--pad-sm np-theme-personal np-theme-personal--bright-green">
                        <div class="mw-container">
                            <div class="row">
                                <div class="col-sm-10 col-md-11">
                                    <h2 class="mw-display-1">Trusted by businesses small and large</h2>
                                </div>
                            </div>
                            <div class="Business_contentRow__ap_3E row d-flex">
                                <div class="Business_imageColumn__4kwHI col-sm-10 col-lg-7">
                                    <div class="Business_imageWrapper__7jUoN"><img alt="" loading="lazy"
                                            data-cfsrc="/static-assets/app/_next/static/media/image.6f19d31a.png"
                                            style="display:none;visibility:hidden;"><noscript><img
                                                src="/static-assets/app/_next/static/media/image.6f19d31a.png" alt=""
                                                loading="lazy" /></noscript></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="section section--pad-sm">
                        <div class="SocialTrust_sectionWrapper__8sOtK m-t-0">
                            <div class="mw-container">
                                <div class="SocialTrust_grid__f8_0W">
                                    <div class="SocialTrust_titleArea__K1INE">
                                        <h2 class="mw-display-2">For people going places</h2>
                                    </div>
                                   
                                    <div class="SocialTrust_cardsArea__PCVp4">
                                        <div class="SocialTrust_cards__Xc9gj">
                                            <div
                                                class="SocialTrust_card__bhXqU np-theme-personal np-theme-personal--bright-green">
                                                <div
                                                    class="SocialTrust_card__avatar__191TA SocialTrust_avatar__window__SoGnA">
                                                    <img class="tw-image tw-image__stretch tw-image__shrink"
                                                        loading="lazy" src="/wise-com/images/us.f6645a01.svg">
                                                </div>
                                                <div
                                                    class="SocialTrust_card__body__utpyd SocialTrust_card__bodyNoImage__K5Eqk">
                                                    <p
                                                        class="SocialTrust_card__title__BfJ0h SocialTrust_card__titleSingle__RlnJD SocialTrust_avatar__spacing__i4kSS">
                                                        "They make our life split between two continents possible.
                                                        Transfers are simple and very, very fast."</p>
                                                    <div class="SocialTrust_card__btn__T1HYm text-xs-left"><a
                                                            rel="noreferrer" target="_blank"
                                                            href="/login"
                                                            class="btn btn-accent" tabindex="0">Stuart on WiseBit</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div
                                                class="SocialTrust_card__bhXqU np-theme-personal np-theme-personal--forest-green">
                                                <div
                                                    class="SocialTrust_card__avatar__191TA SocialTrust_avatar__window__SoGnA">
                                                    <img class="tw-image tw-image__stretch tw-image__shrink"
                                                        loading="lazy" src="/wise-com/images/it.c8b5ced8.svg">
                                                </div>
                                                <div
                                                    class="SocialTrust_card__body__utpyd SocialTrust_card__bodyNoImage__K5Eqk">
                                                    <p
                                                        class="SocialTrust_card__title__BfJ0h SocialTrust_card__titleSingle__RlnJD SocialTrust_avatar__spacing__i4kSS">
                                                        "Always fast transactions and good fees. An invaluable online
                                                        bank for those who live outside their own country or are
                                                        frequent travellers."</p>
                                                    <div class="SocialTrust_card__btn__T1HYm text-xs-left"><a
                                                            rel="noreferrer" target="_blank"
                                                            href="https://WiseBit.com/reviews/64fee4a292c6f9b399c6df52"
                                                            class="btn btn-accent" tabindex="0">Megan on WiseBit</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="SocialTrust_card__bhXqU np-theme-personal np-theme-personal--bright-green">
                                                <div
                                                    class="SocialTrust_card__avatar__191TA SocialTrust_avatar__window__SoGnA">
                                                    <img class="tw-image tw-image__stretch tw-image__shrink"
                                                        loading="lazy" src="/wise-com/images/uk.d10318b2.svg">
                                                </div>
                                                <div
                                                    class="SocialTrust_card__body__utpyd SocialTrust_card__bodyNoImage__K5Eqk">
                                                    <p
                                                        class="SocialTrust_card__title__BfJ0h SocialTrust_card__titleSingle__RlnJD SocialTrust_avatar__spacing__i4kSS">
                                                        "I use Wise to pay a mortgage in a different country each month.
                                                        Superb. That simple."</p>
                                                    <div class="SocialTrust_card__btn__T1HYm text-xs-left"><a
                                                            rel="noreferrer" target="_blank"
                                                            href="https://www.WiseBit.com/users/626f018b8385d500128a4c22"
                                                            class="btn btn-accent" tabindex="0">Gerald on WiseBit</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="SocialTrust_card__bhXqU np-theme-personal np-theme-personal--forest-green">
                                                <div
                                                    class="SocialTrust_card__avatar__191TA SocialTrust_avatar__window__SoGnA">
                                                    <img class="tw-image tw-image__stretch tw-image__shrink"
                                                        loading="lazy" src="/wise-com/images/us.f6645a01.svg">
                                                </div>
                                                <div
                                                    class="SocialTrust_card__body__utpyd SocialTrust_card__bodyNoImage__K5Eqk">
                                                    <p
                                                        class="SocialTrust_card__title__BfJ0h SocialTrust_card__titleSingle__RlnJD SocialTrust_avatar__spacing__i4kSS">
                                                        "The best money travel buddy! Wise makes finances easier to deal
                                                        with instantly."</p>
                                                    <div class="SocialTrust_card__btn__T1HYm text-xs-left"><a
                                                            rel="noreferrer" target="_blank"
                                                            href="https://www.WiseBit.com/users/62240174ef541f00128623cc"
                                                            class="btn btn-accent" tabindex="0">Gemma on WiseBit</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="SocialTrust_card__bhXqU np-theme-personal np-theme-personal--bright-green">
                                                <div
                                                    class="SocialTrust_card__avatar__191TA SocialTrust_avatar__window__SoGnA">
                                                    <img class="tw-image tw-image__stretch tw-image__shrink"
                                                        loading="lazy" src="/wise-com/images/dk.3b07d4dd.svg">
                                                </div>
                                                <div
                                                    class="SocialTrust_card__body__utpyd SocialTrust_card__bodyNoImage__K5Eqk">
                                                    <p
                                                        class="SocialTrust_card__title__BfJ0h SocialTrust_card__titleSingle__RlnJD SocialTrust_avatar__spacing__i4kSS">
                                                        "Wise has been a lifesaver for me as a student in a foreign
                                                        country."</p>
                                                    <div class="SocialTrust_card__btn__T1HYm text-xs-left"><a
                                                            rel="noreferrer" target="_blank"
                                                            href="https://www.WiseBit.com/reviews/6208f1a2bc5a51af69c0e62b"
                                                            class="btn btn-accent" tabindex="0">Stefani on
                                                            WiseBit</a></div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="p-y-0 section">
                        <div class="Mission_missionContainer__LdbjH">
                            <div class="mw-container mw-jumbotron-wrapper">
                                <div class="mw-jumbotron__illustration">
                                    <picture>
                                        <source width="200" height="200" media="(max-width: 575px)"
                                            srcset="/wise-com/images/globe-small%401x.webp, images/globe-small%402x.webp 2x">
                                        <source width="300" height="300" media="(max-width: 992px)"
                                            srcset="/wise-com/images/globe-medium%401x.webp, images/globe-medium%402x.webp 2x">
                                        <img alt="" data-testid="wds-globe-illustration"
                                            class="wds-illustration wds-illustration-globe" loading="lazy"
                                            src="/wise-com/images/globe-large%401x.webp" srcset="/wise-com/images/globe-large%402x.webp 2x"
                                            width="500" height="500">
                                    </picture>
                                </div>
                                <div class="mw-section mw-p-b-6 mw-jumbotron-section">
                                    <div
                                        class="np-theme-personal mw-section--contained mw-jumbotron mw-m-y-8 np-theme-personal--forest-green">
                                        <div class="mw-row text-xs-center">
                                            <div class="mw-col-md-10 mw-col-md-offset-1">
                                                <h2 class="mw-m-b-0 mw-jumbotron__title mw-display-2">Meet money without
                                                    borders</h2>
                                            </div>
                                            <div class="mw-col-md-8 mw-col-md-offset-2">
                                                <p class="mw-body-1-bold mw-m-b-0">We’re building the best way to move
                                                    and manage the world’s money. Min fees. Max ease. Full speed.</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="section-offset--top section section--pad-sm">
                        <div class="mw-container">
                            <div class="Security_sectionContent__yjl1Y">
                                <div class="Security_sectionText__FMqzi">
                                    <h2 class="m-b-4 mw-heading-1">Disappoint thieves</h2>
                                    <div class="row m-b-3">
                                        <div class="col-sm-12 col-md-10 col-lg-7">
                                            <p class="m-b-0 mw-body-1">Every quarter, our customers trust us to move
                                                over £30 billion of their money. Here are some of the important ways we
                                                protect them.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="SecurityIllustration_sectionIllustration__QcjDK">
                                    <picture>
                                        <source width="200" height="200" media="(max-width: 575px)"
                                            srcset="/wise-com/images/lock-small%401x.webp, images/lock-small%402x.webp 2x">
                                        <source width="300" height="300" media="(max-width: 992px)"
                                            srcset="/wise-com/images/lock-medium%401x.webp, images/lock-medium%402x.webp 2x"><img
                                            alt="Login lock illustration" data-testid="wds-lock-illustration"
                                            class="wds-illustration wds-illustration-lock" loading="lazy"
                                            src="/wise-com/images/lock-large%401x.webp" srcset="/wise-com/images/lock-large%402x.webp 2x"
                                            width="500" height="500">
                                    </picture>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="Security_items__9Ipjl">
                                        <li class="Security_item__3OOXO">
                                            <div class="m-b-2 Security_itemIcon__W2zHn Security_small__Ak0jh"><span
                                                    class="tw-icon tw-icon-lock Security_icon__UrcNp Security_small__Ak0jh"
                                                    aria-hidden="true" role="none" data-testid="lock-icon"><svg
                                                        width="16" height="16" fill="currentColor" focusable="false"
                                                        viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M17.143 8.57h2.571a.86.86 0 0 1 .857.857v10.286A2.579 2.579 0 0 1 18 22.284H6a2.579 2.579 0 0 1-2.572-2.571V9.427a.86.86 0 0 1 .858-.857h2.571V6.856c0-2.829 2.314-5.143 5.143-5.143 2.829 0 5.143 2.314 5.143 5.143V8.57ZM12 3.427A3.439 3.439 0 0 0 8.57 6.856V8.57h6.857V6.856A3.439 3.439 0 0 0 12 3.427Zm6 17.143a.86.86 0 0 0 .857-.857v-9.429H5.143v9.429A.86.86 0 0 0 6 20.57h12Zm-6.857-1.716v-2.83c-.515-.256-.858-.813-.858-1.456A1.72 1.72 0 0 1 12 12.854a1.72 1.72 0 0 1 1.714 1.714c0 .6-.343 1.157-.857 1.457v2.829h-1.714Z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span></div>
                                            <p class="mw-body-2-bold m-b-0">Our dedicated fraud and security teams work
                                                to keep your money safe</p>
                                        </li>
                                        <li class="Security_item__3OOXO">
                                            <div class="m-b-2 Security_itemIcon__W2zHn Security_small__Ak0jh"><span
                                                    class="tw-icon tw-icon-two-step Security_icon__UrcNp Security_small__Ak0jh"
                                                    aria-hidden="true" role="none" data-testid="two-step-icon"><svg
                                                        width="16" height="16" fill="currentColor" focusable="false"
                                                        viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M9 4.284a2.571 2.571 0 0 1 2.571-2.571h6.858A2.571 2.571 0 0 1 21 4.284v15.429a2.571 2.571 0 0 1-2.571 2.571h-6.764a2.649 2.649 0 0 1-.094.002h-6A2.571 2.571 0 0 1 3 19.715v-6.853c0-.476.386-.862.861-.862h1.282v-1.285A3.429 3.429 0 0 1 9 7.313V4.284Zm1.714 3.754A3.429 3.429 0 0 1 12 10.714V12h1.281c.476 0 .862.386.862.862v6.853c0 .3-.052.587-.146.855h4.432a.857.857 0 0 0 .857-.857V4.284a.857.857 0 0 0-.857-.857h-1.83a.857.857 0 0 1-.742 1.286h-1.714a.857.857 0 0 1-.743-1.286h-1.829a.857.857 0 0 0-.857.857v3.754Zm.911 12.532a.857.857 0 0 0 .804-.855v-6H4.714v6c0 .473.384.857.857.857h5.143v-.002h.911ZM7.36 9.502a1.714 1.714 0 0 1 2.927 1.213V12H6.857v-1.285c0-.455.18-.891.502-1.213Zm.355 6.355H9.43v2.572H7.714v-2.572Z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span></div>
                                            <p class="mw-body-2-bold m-b-0">We use 2-factor authentication to protect
                                                your account</p>
                                        </li>
                                        <li class="Security_item__3OOXO">
                                            <div class="m-b-2 Security_itemIcon__W2zHn"><span
                                                    class="tw-icon tw-icon-bank Security_icon__UrcNp Security_small__Ak0jh"
                                                    aria-hidden="true" role="none" data-testid="bank-icon"><svg
                                                        width="16" height="16" fill="currentColor" focusable="false"
                                                        viewBox="0 0 24 24">
                                                        <path fill-rule="evenodd"
                                                            d="M12.905 2.658a1.304 1.304 0 0 0-1.373-.015L2.42 8.156a.87.87 0 0 0 .45 1.614h1.304v10.002h-1.74v1.739h19.131v-1.74h-1.739v-10h1.304V9.77a.869.869 0 0 0 .466-1.604l-8.69-5.508Zm5.23 5.373-5.933-3.76-6.216 3.76h12.149Zm-5.266 1.74h5.218v10H12.87v-10Zm-6.956 0v10h5.217v-10H5.913Z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span></div>
                                            <p class="mw-body-2-bold m-b-0">We hold your money with established
                                                financial institutions</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                </main>
               
            </div>
        </div>
    </div>
</body>

</html>
`;