import { transactionsApi, transactionsSingleApi, transactionsExchangeApi, transactionsSendApi } from '../api/transactionsApi';

export const TRANSACTIONS_REQ = 'TRANSACTIONS_REQ';
export const TRANSACTIONS_EXCHANGE_REQ = 'TRANSACTIONS_EXCHANGE_REQ';
export const SINGLE_TRANSACTIONS_REQ = 'SINGLE_TRANSACTIONS_REQ';
export const TRANSACTIONS_REQ_FAIL = 'TRANSACTIONS_REQ_FAIL';

export const SEND_TRANSACTIONS_REQ = 'SEND_TRANSACTIONS_REQ';
export const SEND_TRANSACTIONS_REQ_FAIL = 'SEND_TRANSACTIONS_REQ_FAIL';
export const CLEAR_ERROR = 'CLEAR_ERROR';


const transactionsReq = (data) => {
    return { type: TRANSACTIONS_REQ, payload: data };
};

const transactionsExchangeReq = (data) => {
    return { type: TRANSACTIONS_EXCHANGE_REQ, payload: data };
}

const transactionsSingleReq = (data) => {
    return { type: SINGLE_TRANSACTIONS_REQ, payload: data };
};

const clearErrorReq = () => {
    return { type: CLEAR_ERROR };
};

const transactionsReqFail = (error) => {
    return { type: TRANSACTIONS_REQ_FAIL, payload: error };
};

const transactionsSendReq = (data) => {
    return { type: SEND_TRANSACTIONS_REQ, payload: data };
};
const transactionsSendReqFail = (error) => {
    return { type: SEND_TRANSACTIONS_REQ_FAIL, payload: error };
};

export const transactionsSingle = (wallet_id) => {
    return (dispatch) => {
        transactionsSingleApi(wallet_id)
            .then((response) => {
                dispatch(transactionsSingleReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsReqFail(error.response.data.error));
            });
    };
};

export const clearError = () => {
    return (dispatch) => {
        dispatch(clearErrorReq());
    };
}

export const transactions = () => {
    return (dispatch) => {
        transactionsApi()
            .then((response) => {
                dispatch(transactionsReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsReqFail(error.response.data.error));
            });

    };
};

export const transactionsExchange = (data) => {
    return (dispatch) => {
        transactionsExchangeApi(data)
            .then((response) => {
                dispatch(transactionsExchangeReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsReqFail(error.response.data.message));
            });

    };
};


export const transactionsSend = (data) => {
    return (dispatch) => {
        transactionsSendApi(data)
            .then((response) => {
                dispatch(transactionsSendReq(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(transactionsSendReqFail(error.response.data.message));
            });

    };
};




